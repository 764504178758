var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col w-5/6 ml-auto mr-auto"},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"label":"Customer Code","value":this.customerData.customer_code,"disabled":""}})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"label":"Customer Name","value":this.customerData.customer_name,"disabled":""}})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"label":"Customer Address","value":this.customerAddress,"disabled":""}})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"label":"Customer Contact","value":this.customerData.contact_name +
          ' ' +
          this.customerData.contact_mobile,"disabled":""}})],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Day")]),_c('VueSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"day","options":[
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ]},model:{value:(_vm.daySelected),callback:function ($$v) {_vm.daySelected=$$v},expression:"daySelected"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('day')),expression:"errors.has('day')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("day")))])],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Odd Even")]),_c('VueSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"odd_even","options":['Odd', 'Even']},model:{value:(_vm.oddEvenSelected),callback:function ($$v) {_vm.oddEvenSelected=$$v},expression:"oddEvenSelected"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('odd_even')),expression:"errors.has('odd_even')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("odd_even")))])],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":_vm.handleSubmit}},[_vm._v("Submit")]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"type":"border","color":"danger"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }