<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Customer Code"
          :value="this.customerData.customer_code"
          disabled
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Customer Name"
          :value="this.customerData.customer_name"
          disabled
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Customer Address"
          :value="this.customerAddress"
          disabled
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Customer Contact"
          :value="
            this.customerData.contact_name +
            ' ' +
            this.customerData.contact_mobile
          "
          disabled
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Day</label>
        <VueSelect
          name="day"
          v-model="daySelected"
          v-validate="'required'"
          :options="[
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ]"
        />
        <span class="text-danger text-sm" v-show="errors.has('day')">{{
          errors.first("day")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Odd Even</label>
        <VueSelect
          name="odd_even"
          v-validate="'required'"
          v-model="oddEvenSelected"
          :options="['Odd', 'Even']"
        />
        <span class="text-danger text-sm" v-show="errors.has('odd_even')">{{
          errors.first("odd_even")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import VueSelect from "vue-select";
export default {
  components: {
    VueSelect,
  },
  props: {
    baseUrl: {
      type: String,
    },
    customerData: {
      type: Object,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        daySelected: "",
        oddEvenSelected: "",
        customerAddress: "",
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.$http
            .post(this.baseUrl + "/plan", {
              day: this.daySelected,
              odd_even: this.oddEvenSelected,
              customer_address_id: this.customerData.id,
            })
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "New Data Created",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    setCustomerAddress() {
      this.customerAddress =
        (this.customerData.address ? this.customerData.address : "") +
        (this.customerData.sub_district
          ? ", " + this.customerData.sub_district
          : "") +
        (this.customerData.district ? ", " + this.customerData.district : "") +
        (this.customerData.city ? ", " + this.customerData.city : "") +
        (this.customerData.province ? ", " + this.customerData.province : "") +
        (this.customerData.country ? ", " + this.customerData.country : "") +
        (this.customerData.postal_code ? this.customerData.postal_code : "");
    },
  },
  mounted() {
    this.setCustomerAddress();
  },
  computed: {},
  watch: {
    customerData: function () {
      this.setCustomerAddress();
    },
  },
};
</script>
