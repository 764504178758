<template>
  <div>
    <vx-card title="Customer Visit Plan">
      <div class="vx-row ml-1">
        <div class="vx-col w-1/2">
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Employee ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              :
              <b>{{ workIdNumber }}</b>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-1/3 w-full">
              <span>Sales Name</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              :
              <b>{{ name }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div v-bind:class="[detail ? detailShow : '', detailHide]">
          <div class="vx-col sm:w-1/1 w-full mb-base">
            <data-table-customer
              @close="handleClose"
              @open="handlePlan"
              :baseUrl="this.baseUrl + '/' + this.personalId"
            ></data-table-customer>
          </div>
        </div>
        <transition name="detail-fade">
          <div
            v-if="detail"
            v-bind:class="[detail ? detailShow : '', detailHide]"
          >
            <vs-row>
              <vs-col
                style="padding-bottom: 8px"
                vs-offset="8"
                vs-type="flex"
                vs-justify="rigth"
                vs-align="rigth"
                vs-w="4"
              >
                <vs-button
                  class="ml-auto"
                  size="small"
                  v-on:click="handleClose"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                  >Close</vs-button
                >
              </vs-col>
              <vs-col
                class="vertical-divider"
                vs-offset="0"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="1"
              >
                <vs-button
                  size="small"
                  v-on:click="handleClose"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                ></vs-button>
              </vs-col>

              <FormPlan
                @close="handleClose"
                :customer-data="customerData"
                :baseUrl="this.baseUrl + '/' + this.personalId"
              ></FormPlan>
            </vs-row>
          </div>
        </transition>
      </div>
    </vx-card>
    <vx-card title="Plan List">
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Day</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <VueSelect
            v-model="daySelected"
            :options="[
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ]"
          />
        </div>
      </div>
      <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Odd Even</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <VueSelect v-model="oddEvenSelected" :options="['Odd', 'Even']" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <data-table-plan
            :baseUrl="this.baseUrl + '/' + this.personalId"
            :day="this.daySelected"
            :odd-even="this.oddEvenSelected"
            :detail="this.detail"
          ></data-table-plan>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import DataTableCustomer from "./DataTableCustomer.vue";
import DataTablePlan from "./DataTablePlan.vue";
import FormPlan from "./Form.vue";
import VueSelect from "vue-select";
export default {
  components: {
    DataTableCustomer,
    DataTablePlan,
    VueSelect,
    FormPlan,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      personalId: null,
      baseUrl: "/api/v1/sales-force-automation/customer-visit-plan",
      workIdNumber: "",
      name: "",
      daySelected: "",
      oddEvenSelected: "",
      customerData: null,
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    handlePlan(data) {
      this.customerData = data;
      this.handleOpen();
    },
    getSales() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.personalId + "/sales", {})
        .then((resp) => {
          if (resp.status == "success") {
            this.workIdNumber = resp.data.work_id_number;
            this.name = resp.data.name;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.personalId = this.$route.params.id;
    this.getSales();
  },
  watch: {},
};
</script>

 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
